import BaseController from './base_controller'

export default class extends BaseController {
  static targets = [
    'icon',
    'negativeIconTemplate',
    'positiveIconTemplate',
    'cancel',
    'submit',
    'title',
    'description',
    'descriptionWrapper',
    'guardPhraseTemplate',
    'guardPhraseField',
    'guardPhrase',
    'guardPhraseInput',
  ]

  static values = {
    type: { type: String, default: 'neutral' },
    defaultTitle: String,
    defaultSubmit: String,
    defaultCancel: String,
    title: String,
    submit: String,
    cancel: String,
    description: String,
    eventDetail: Object,
    guardPhrase: String,
    initiator: String,
    htmlSafeDescription: { type: Boolean, default: false },
  }

  confirm(event) {
    event.preventDefault()
    if (!this.validateGuardPhrase()) return

    this.dispatch('confirm', { detail: { confirm: true, ...this.eventDetailValue } })
    this.element.hide()
  }

  cancel() {
    this.dispatch('confirm', { detail: { confirm: false, ...this.eventDetailValue } })

    if (this.hasInitiatorValue) {
      let confirmAction = document.querySelector(this.initiatorValue)
      confirmAction.classList.remove('disabled')
      confirmAction.disabled = false
    }

    this.element.hide()
  }

  typeValueChanged() {
    this.updateToType(this.typeValue)
  }

  titleValueChanged() {
    this.titleTarget.textContent = this.titleValue
  }

  descriptionValueChanged() {
    if (this.htmlSafeDescriptionValue) {
      this.descriptionTarget.innerHTML = this.descriptionValue
    } else {
      this.descriptionTarget.textContent = this.descriptionValue
    }
  }

  cancelValueChanged() {
    this.cancelTarget.textContent = this.cancelValue
  }

  submitValueChanged() {
    this.submitTarget.textContent = this.submitValue
  }

  updateToType(type) {
    if (type === 'negative') {
      this.iconTarget.replaceChildren(this.negativeIconTemplateTarget.content.cloneNode(true))
      this.submitTarget.classList = 'cf-button-danger'
    }

    if (type === 'positive') {
      this.iconTarget.replaceChildren(this.positiveIconTemplateTarget.content.cloneNode(true))
      this.submitTarget.classList = 'cf-button'
    }

    if (type === 'neutral' || !type) {
      this.iconTarget.replaceChildren()
      this.submitTarget.classList = 'cf-button'
    }
  }

  // Phrase Guard functionality
  guardPhraseValueChanged() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.hasGuardPhraseValue && this.guardPhraseValue ? this.enableGuardPhrase() : this.disableGuardPhrase()
  }

  enableGuardPhrase() {
    this.disableSubmit()
    this.descriptionWrapperTarget.appendChild(this.guardPhraseTemplateTarget.content.cloneNode(true))
  }

  guardPhraseTargetConnected() {
    // Fill in the guard phrase name in the template
    this.guardPhraseTarget.textContent = this.guardPhraseValue
  }

  validateGuardPhrase() {
    if (!this.hasGuardPhraseValue) return true
    return this.guardPhraseInputTarget.value === this.guardPhraseValue
  }

  toggleSubmit() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.validateGuardPhrase() ? this.enableSubmit() : this.disableSubmit()
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  disableGuardPhrase() {
    this.enableSubmit()

    if (!this.hasGuardPhraseFieldTarget) return

    this.guardPhraseFieldTarget.remove()
  }

  reset() {
    this.typeValue = ''
    this.cancelValue = this.defaultCancelValue
    this.submitValue = this.defaultSubmitValue
    this.titleValue = this.defaultTitleValue

    if (this.hasGuardPhraseFieldTarget) {
      this.element.removeAttribute('data-shoelace--confirm-guard-phrase-value')
      this.guardPhraseFieldTarget.remove()
    }
  }
}
