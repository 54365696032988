import '@github/clipboard-copy-element'
import tippy from 'tippy.js'
import ApplicationController from './application_controller'
import { i18n } from '../i18n-js/config'
import Cookies from 'js-cookie'

/* A stimulus controller that wraps the clipboard-copy web component.

  USAGE:
  1. Wrap an icon (or whatever you intend for the user to click) with the
     <clipboard-copy> custom element.
  2. Add the data-controller="clipboard-copy" attribute
  3. Optionally pass in custom class names if you need to apply different
     classnames to the copy button after it is clicked. By default, we apply
     'fa fa-check text-green-500'.
  4. Let stimulus know about the copy button by adding data-clipboard-copy-target="copyBtn"
     to the icon or whatever you wrapped with the custom element in step 1.

    See https://github.com/github/clipboard-copy-element#data-sources
    for the various ways you can identify the source data to copy. Here's
    an example using the `for` attribute:

    <div id="site-url">Something something</div> // The thing you want to copy

    <clipboard-copy for="site-url"
      class= "cf-button-icon cf-button-secondary"
      data-controller="clipboard-copy"
      data-action="clipboard-copy->clipboard-copy#handleCopy"
      data-clipboard-copy-copied-class-value="far fa-check text-green-500"
    >
      <i class="far fa-link" data-clipboard-copy-target="copyBtn"></i>
    </clipboard-copy>
*/

export default class extends ApplicationController {
  static targets = ['copyBtn']
  static values = {
    copiedClass: String,
    tooltipMessage: { type: String, default: 'Click to Copy' },
  }

  connect() {
    const locale = Cookies.get('locale') || 'en'
    tippy(this.element, {
      content:
        this.tooltipMessageValue == 'Click to Copy'
          ? i18n.t('stimulus.clipboard_copy.click_to_copy', { lng: locale })
          : this.tooltipMessageValue,
    })
  }

  handleCopy() {
    const copyBtnTargetClasses = [...this.copyBtnTarget.classList]
    const isCopiedClasses = (this.copiedClassValue || 'far fa-check text-green-500').split(' ')

    copyBtnTargetClasses.forEach((c) => this.copyBtnTarget.classList.remove(c))
    isCopiedClasses.forEach((c) => this.copyBtnTarget.classList.add(c))

    setTimeout(() => {
      isCopiedClasses.forEach((c) => this.copyBtnTarget.classList.remove(c))
      copyBtnTargetClasses.forEach((c) => this.copyBtnTarget.classList.add(c))
      this.element.blur()
    }, 1500)
  }
}
